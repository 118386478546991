/* eslint-disable no-param-reassign */
import validate from 'validate.js';
import { Cryptography } from 'mp-common-js';

export const validateFields = (fields, constraints, errorFields, callback) => {
  let isValid = true;
  const errors = validate(fields, constraints);

  if (errors) {
    Object.keys(errors).forEach((key) => {
      if (errorFields[`${key}Error`] !== undefined) {
        const error = errors[key][0];
        errorFields[`${key}Error`] = error;
      }
    });
    isValid = false;
  }

  callback(errorFields);
  return isValid;
};

export const extractParameters = (str) => {
  const queryString = str.substring(1);

  return queryString
    .split('&')
    .map((param) => param.split('='))
    .reduce((obj, value) => {
      value = value.map((v) => decodeURIComponent(v));
      obj[value[0]] =
        value[1] === 'true' || value[1] === 'false'
          ? JSON.parse(value[1])
          : value[1];
      return obj;
    }, {});
};

export const isTokenExpired = (token) => {
  const tokenDetails = JSON.parse(token);
  const decodedToken = Cryptography.decodeJwt(tokenDetails.access_token);
  const currentTime = Date.now() / 1000;

  return decodedToken.exp < currentTime;
};

export const buildDeviceFromParams = (params) => {
  const { brand, model, version, description } = params;
  const color = description;
  return { brand, model, version, variant: { color } };
};

export const iframeResponse = (actionType, payload) => {
  window.parent.postMessage({ actionType, payload }, '*');
};

export const oemName = (name) => {
  if (name)
    switch (name) {
      case 'k':
        return 'K Wearables';
      case 'pda':
        return 'Olympic';
      case 'laks':
        return 'LAKS PAY';
      case 'kiroco':
        return 'K-pay';
      case 'rosan':
        return 'Rosan Pay';
      case 'tovi sorga':
        return 'Tovi Sorga';
      default:
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
  return null;
};
