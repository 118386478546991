import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

const HelpText = ({ error, field, style }) => {
  if (typeof error !== 'undefined' && error !== '') {
    return (
      <FormHelperText error id={`${field}-error-text`} style={style}>
        {error}
      </FormHelperText>
    );
  }

  return <div />;
};

HelpText.propTypes = {
  error: PropTypes.string,
  field: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

HelpText.defaultProps = {
  style: null,
  error: undefined,
};

export default HelpText;
