const styles = (theme) => ({
  mainContainer: {
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '424px',
    '@media (max-width: 425px)': {
      maxWidth: '100%',
    },
  },
  orderConfirmationContent: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '300px',
  },
  header: {
    fontSize: '20px',
  },
  subheader: {
    textAlign: 'center',
  },
  deviceInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 20px',
    borderRadius: '12px',
  },
  img: {
    height: '150px',
    width: '150px',
    objectFit: 'contain',
    margin: '5px 10px',
  },
  deviceDetails: {
    textTransform: 'capitalize',
  },
  deviceModel: {
    fontSize: '16px',
    fontWeight: 500,
  },
  deviceVariant: {
    fontSize: '14px',
    fontWeight: 300,
  },
});

export default styles;
