import { push } from 'connected-react-router';
import { Services } from 'mp-common-js';
import { SIGN_UP } from './ActionTypes';

import { iframeResponse } from '../../utils';

export const signUpEvent = (status, error) => ({
  type: SIGN_UP,
  status,
  error,
});

export function signUpCompletedAction(routerState) {
  return (dispatch) => {
    dispatch(push('/signin', routerState));
  };
}

export function signUpAction(credentials, options) {
  return (dispatch, getState) => {
    const { device, cardConfig } = getState().enrollment;
    const { emailSender, issuerName } = cardConfig;
    const { brand } = device;
    const signUpOptions = {
      ...options,
      emailSender,
      issuerName,
      brand,
    };
    dispatch(signUpEvent('processing'));
    return Services.SecurityService.signUp(credentials, signUpOptions).then(
      () => {
        dispatch(signUpEvent('success'));
      },
      (error) => {
        iframeResponse('ERROR', error.response.data.message);
        dispatch(signUpEvent('error', error.response.status));
      },
    );
  };
}
