import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import './config/i18n';

import AppContainer from './AppContainer';
import { unregister } from './registerServiceWorker';
import store from './state/store';
import { version } from '../package.json';

import './index.css';

// eslint-disable-next-line no-console
console.log({ version });
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

unregister();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<CircularProgress />}>
      <AppContainer />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
