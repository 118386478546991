export default {
  palette: {
    primary: {
      main: '#AECFE3',
    },
    text: { primary: '#333' },
    background: '#ffffff',
    action: {
      hover: '#AECFE3',
      selected: '#AECFE3',
    },
  },
};
