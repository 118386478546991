import React from 'react';
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import HelpText from '../../components/help-text';
import CountriesSelect from '../../kernel/countries-select/CountriesSelect';
import { CreditCardField } from '../../components/credit-card';

import { validateFields } from '../../utils';
import { withTranslation } from '../../config/i18n';

import { cardConstrains, billingConstrains } from './Constraints';

import styles from './styles';

function CleaveInput(props) {
  const { inputRef, value, ...rest } = props;
  return (
    <Cleave
      value={value}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      options={{ date: true, datePattern: ['m', 'y'] }}
      {...rest}
    />
  );
}

export class CreditCardForm extends React.Component {
  static propTypes = {
    card: PropTypes.shape({
      accountNumber: PropTypes.string.isRequired,
      cardholderName: PropTypes.string.isRequired,
      expiryMonth: PropTypes.string.isRequired,
      expiryYear: PropTypes.string.isRequired,
      securityCode: PropTypes.string,
    }).isRequired,
    billingAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      country: PropTypes.string,
    }).isRequired,
    navigationComponentProps: PropTypes.shape({
      onNext: PropTypes.func.isRequired,
      onPrev: PropTypes.func,
    }).isRequired,
    t: PropTypes.func.isRequired,
    onCardCheck: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { card, billingAddress } = props;

    this.inputs = {};

    this.state = {
      card,
      billingAddress,
      showErrors: false,
      eligibilityError: '',
      errorMessages: {
        cardholderNameError: '',
        securityCodeError: '',
        line1Error: '',
        line2Error: '',
        postalCodeError: '',
        countryError: '',
        expiryDateError: '',
        expiryMonthError: '',
        expiryYearError: '',
      },
    };
  }

  handleCheckCardDetails = () => {
    const {
      navigationComponentProps,
      onCardCheck,
      handleUpdate,
      isFetching,
      cardConfig,
      t,
    } = this.props;
    const { card, billingAddress, errorMessages } = this.state;

    const { cvcRequired, addressRequired, cardholderNameRequired } = cardConfig;

    if (card.expiryDate) {
      const [expiryMonth, expiryYear] = card.expiryDate.split('/');
      card.expiryMonth = expiryMonth;
      card.expiryYear = expiryYear;
    }

    const isFormValid =
      validateFields(
        card,
        cardConstrains(
          t,
          cvcRequired !== false,
          cardholderNameRequired !== false,
        ),
        errorMessages,
        (errors) => this.setState({ ...errors }),
      ) &&
      validateFields(
        billingAddress,
        billingConstrains(t, addressRequired),
        errorMessages,
        (errors) => this.setState({ ...errors }),
      );

    handleUpdate(card, billingAddress);

    if (isFormValid) {
      if (!isFetching) {
        if (cardConfig.cvcRequired === false) {
          card.securityCode = null;
          }
        onCardCheck(
          {
            ...card,
            ...(addressRequired !== false
              ? { billingAddress: { ...billingAddress } }
              : null),
          },
          (response) => {
            if (typeof navigationComponentProps !== 'undefined') {
              navigationComponentProps.onNext(response);
            }
          },
          (errorMessage) => {
            const message =
              errorMessage || t('generalError.somethingWentWrong');
            this.setState({ showErrors: true, eligibilityError: message });
          },
        );
      }
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleCardFieldChange = (fieldName) => (event) => {
    const fieldValue = event.target.value;

    this.setState((state) => {
      const { card } = state;
      card[fieldName] = fieldValue;

      return this.updateFieldValues(card, fieldName);
    });
  };

  handleBillingFieldChange = (fieldName) => (event) => {
    const fieldValue = event.target.value;

    this.setState((state) => {
      const { billingAddress } = state;
      billingAddress[fieldName] = fieldValue;

      return this.updateFieldValues(billingAddress, fieldName);
    });
  };

  updateFieldValues = (type, fieldName) => {
    const { errorMessages } = this.state;

    const expiryDateErrorMessages = fieldName === 'expiryDate' && {
      expiryMonthError: '',
      expiryYearError: '',
    };

    return {
      type,
      showErrors: false,
      errorMessages: {
        ...errorMessages,
        ...expiryDateErrorMessages,
        [`${fieldName}Error`]: '',
      },
    };
  };

  render() {
    const {
      navigationComponent,
      navigationComponentProps,
      isFetching,
      t,
      style,
      classes,
      cardConfig = {},
    } = this.props;
    const {
      showErrors,
      errorMessages,
      card,
      billingAddress,
      eligibilityError,
      expiryDateFocused,
    } = this.state;
    const NavigationComponent = navigationComponent;

    const { cvcRequired, addressRequired, cardholderNameRequired } = cardConfig;

    return (
      <div className={classes.creditCardFormPage}>
        <Grid
          data-test="creditcard-form-page"
          container
          justify="center"
          direction="row"
          alignItems="flex-start"
          spacing={2}
          className={classes.mainContainer}
          style={style}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Grid item>
              <CreditCardField
                data-test="creditcard-field"
                accountNumber={card.accountNumber}
                readOnly
                margin="normal"
                fullWidth
              />
            </Grid>
            {cardholderNameRequired !== false && (
              <Grid item>
                <Tooltip title={t('creditCardForm.cardholderNamePlaceholder')}>
                  <TextField
                    data-test="cardholder-name"
                    required
                    id="cardholderName"
                    label={t('creditCardForm.cardholderNameLabel')}
                    error={false}
                    onChange={this.handleCardFieldChange('cardholderName')}
                    value={card.cardholderName}
                    placeholder={t('creditCardForm.cardholderNamePlaceholder')}
                    margin="normal"
                    fullWidth
                  />
                </Tooltip>
                {showErrors && (
                  <HelpText
                    error={errorMessages.cardholderNameError}
                    field="cardholderName"
                  />
                )}
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={3}>
                <Grid className={classes.expiryDateContainer} item xs={12}>
                  <Typography
                    color={expiryDateFocused ? 'primary' : 'textSecondary'}
                    variant="caption"
                  >
                    {t('creditCardForm.expiryDateLabel')}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    data-test="expiry-date"
                    label={t('creditCardForm.expiryDatePlaceholder')}
                    onChange={this.handleCardFieldChange('expiryDate')}
                    value={card.expiryDate || ''}
                    placeholder={t('creditCardForm.expiryDatePlaceholder')}
                    InputProps={{
                      inputComponent: CleaveInput,
                    }}
                    InputLabelProps={{ shrink: !!card.expiryDate }}
                    fullWidth
                  />
                  {showErrors && (
                    <HelpText
                      error={errorMessages.expiryDateError}
                      field="expiryDate"
                    />
                  )}
                  {showErrors && !errorMessages.expiryDateError && (
                    <HelpText
                      error={errorMessages.expiryMonthError}
                      field="expiryMonth"
                    />
                  )}
                  {showErrors && !errorMessages.expiryDateError && (
                    <HelpText
                      error={errorMessages.expiryYearError}
                      field="expiryYear"
                    />
                  )}
                </Grid>
                {cvcRequired !== false && (
                  <Grid item xs={12} lg={6}>
                    <Tooltip
                      disableHoverListener
                      title={t('creditCardForm.securityCodeTooltip')}
                    >
                      <TextField
                        data-test="securityCode-field"
                        required={cvcRequired !== false}
                        id="securityCode"
                        label={t('creditCardForm.securityCodeLabel')}
                        error={false}
                        onChange={this.handleCardFieldChange('securityCode')}
                        value={card.securityCode}
                        placeholder={t('creditCardForm.securityCodePlaceholder')}
                        fullWidth
                      />
                    </Tooltip>
                    {showErrors && (
                      <HelpText
                        error={errorMessages.securityCodeError}
                        field="securityCodeError"
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {addressRequired !== false && (
            <Grid item xs={12} md={6} lg={6}>
              <Grid item>
                <Tooltip title={t('creditCardForm.line1Tooltip')}>
                  <TextField
                    data-test="address-line1"
                    required
                    id="line1"
                    label={t('creditCardForm.line1Label')}
                    error={false}
                    onChange={this.handleBillingFieldChange('line1')}
                    value={billingAddress.line1}
                    placeholder={t('creditCardForm.line1Placeholder')}
                    margin="normal"
                    fullWidth
                  />
                </Tooltip>
                {showErrors && (
                  <HelpText
                    error={errorMessages.line1Error}
                    field="line1Error"
                  />
                )}
              </Grid>
              <Grid item>
                <Tooltip title={t('creditCardForm.line2Tooltip')}>
                  <TextField
                    data-test="address-line2"
                    id="line2"
                    label={t('creditCardForm.line2Label')}
                    error={false}
                    onChange={this.handleBillingFieldChange('line2')}
                    value={billingAddress.line2}
                    placeholder={t('creditCardForm.line2Placeholder')}
                    margin="normal"
                    fullWidth
                  />
                </Tooltip>
                {showErrors && (
                  <HelpText error={errorMessages.line2Error} field="line2" />
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ marginTop: '5px' }}
                >
                  <Grid item xs={12} lg={6}>
                    <Tooltip title={t('creditCardForm.postalCodeTooltip')}>
                      <TextField
                        data-test="postal-code"
                        required
                        id="postalCode"
                        label={t('creditCardForm.postalCodeLabel')}
                        error={false}
                        onChange={this.handleBillingFieldChange('postalCode')}
                        value={billingAddress.postalCode}
                        placeholder={t('creditCardForm.postalCodePlaceholder')}
                        fullWidth
                      />
                    </Tooltip>
                    {showErrors && (
                      <HelpText
                        error={errorMessages.postalCodeError}
                        field="postalCode"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CountriesSelect
                      data-test="contries-select"
                      value={billingAddress.country}
                      onChange={this.handleBillingFieldChange('country')}
                    />
                    {showErrors && (
                      <HelpText
                        error={errorMessages.countryError}
                        field="countryCode"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.errorContainer}>
          {isFetching && <CircularProgress size={18} />}
          {showErrors && (
            <HelpText error={eligibilityError} field="eligibility" />
          )}
        </div>

        {NavigationComponent && (
          <NavigationComponent
            data-test="navigation-component"
            {...navigationComponentProps}
            onNext={this.handleCheckCardDetails}
          />
        )}
      </div>
    );
  }
}

export default withTranslation(withStyles(styles)(CreditCardForm));
