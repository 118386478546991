import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from '../../config/i18n';

import StyledButton from '../styled-button/StyledButon';
import IframeFooter from '../iframe-footer/IframeFooter';

import { iframeResponse } from '../../utils';

import styles from './styles';

/**
 * Previous button defalts to:
 * - Back label and previous page back buton action
 * - Next label, but require a method
 * - Hide previous button by setting hidePrev to true
 */

const handleCancel = () => iframeResponse('USER_HAS_CANCELLED');

const handleBack = (history, onBack, user) =>
  user ? onBack() : history.goBack();

const EcommFooter = ({
  prevType,
  nextType,
  onPrev,
  onNext,
  hidePrev,
  t,
  history,
  classes,
  onBack,
  user,
}) => {
  const prevButtonLabel =
    prevType === 'cancel' ? t('button.cancel') : t('button.back');
  const prevButtonAction =
    onPrev ||
    (prevType === 'cancel'
      ? handleCancel
      : () => handleBack(history, onBack, user));
  const nextButtonLabel =
    nextType === 'finish' ? t('button.finish') : t('button.next');

  const prevButtonStyle = hidePrev && { visibility: 'hidden' };

  return (
    <div className={classes.ecommFooter}>
      <div className={classes.footerButtons}>
        <StyledButton small onClick={prevButtonAction} style={prevButtonStyle}>
          {prevButtonLabel}
        </StyledButton>
        <StyledButton onClick={onNext} small>
          {nextButtonLabel}
        </StyledButton>
      </div>
      <div>
        <IframeFooter />
      </div>
    </div>
  );
};
EcommFooter.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func,
};

EcommFooter.defaultProps = {
  onPrev: null,
};

export default withRouter(withTranslation(withStyles(styles)(EcommFooter)));
