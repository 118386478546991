import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../components/styled-button/StyledButon';
import SignUpTerms from '../sign-up-terms/SignUpTerms';

import styles from './styles';

const renderMessage = (t, userExists) => {
  const doesUserExists = userExists ? 'existingEmail' : 'useEmailMessage';
  return t(`signUp.alternative.${doesUserExists}`);
};

const EmailConfirmationMessage = ({
  t,
  classes,
  email,
  handleEmailCollected,
  userExists,
  isProcessing,
  onBack,
  theme,
}) => (
  <div className={classes.mainContainer}>
    <div>
      <Typography variant="subtitle1" className={classes.header}>
        {t('signUp.alternative.useEmail', { email })}
      </Typography>
    </div>
    <div>
      <Typography className={classes.subheader} variant="caption">
        {renderMessage(t, userExists)}
      </Typography>
    </div>
    <div>
      <StyledButton onClick={() => handleEmailCollected(email, userExists)}>
        {t('signUp.alternative.continue')}
      </StyledButton>
      <StyledButton secondary onClick={onBack}>
        {t('signUp.alternative.useDifferentEmail')}
      </StyledButton>
    </div>
    {!userExists && !isProcessing && <SignUpTerms t={t} theme={theme} />}
  </div>
);
EmailConfirmationMessage.propTypes = {
  t: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  handleEmailCollected: PropTypes.func.isRequired,
  userExists: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  email: PropTypes.string,
};

EmailConfirmationMessage.defaultProps = {
  email: null,
};

export default withStyles(styles)(EmailConfirmationMessage);
