/* eslint-disable no-param-reassign */
import { Services } from 'mp-common-js';

import { i18next } from '../../config/i18n';

import {
  CARD_ELIGIBILITY_CHECK,
  UPDATE_CARD,
  UPDATE_BILLING,
  UPDATE_CARD_NUMBER,
  SET_DEVICE,
  RESET_CARD,
  UPDATE_CARD_VALIDITY,
  SET_CARD_CONFIG,
} from './ActionTypes';
import { iframeResponse } from '../../utils';

export const cardEligibilityCheckEvent = (status, eligibility) => ({
  type: CARD_ELIGIBILITY_CHECK,
  status,
  eligibility,
});

export const setCardConfig = (cardConfig) => ({
  type: SET_CARD_CONFIG,
  cardConfig,
});

export const updateCardNumber = (cardNumber) => ({
  type: UPDATE_CARD_NUMBER,
  cardNumber,
});

export const updateCard = (card) => ({
  type: UPDATE_CARD,
  card,
});

export const updateBilling = (billingAddress) => ({
  type: UPDATE_BILLING,
  billingAddress,
});

export const setCardValidity = (cardValidity) => ({
  type: UPDATE_CARD_VALIDITY,
  cardValidity,
});

export const resetCard = (card) => ({
  type: RESET_CARD,
  card,
});

export const setDevice = (device) => ({
  type: SET_DEVICE,
  device,
});

const handleErrorResponse = (error, onError) => {
  let response = '';
  if (typeof error === 'string') {
    const responseParsed = JSON.parse(error);
    response = responseParsed.message;
  } else if (error.response && error.response.data.code) {
    if (error.response.data.code === 1041) {
      response = decodeURIComponent(escape(error.response.data.message));
    } else
      response = `${i18next.t(`errorCodes.${error.response.data.code}`)} (${
        error.response.data.code
      })`;
  } else if (error.response && error.response.data.message) {
    if (error.response.status === 500)
      response = `${i18next.t(`errorCodes.${error.response.status}`)} (${
        error.response.status
      })`;
    else response = error.response.data.message;
  } else response = error.message;
  onError(response);
  iframeResponse('ERROR', response);
};

export const paymentCardEligibilityCheckAction = (
  accountNumber,
  onSuccess,
  onError,
) => (dispatch, getState) => {
  dispatch(cardEligibilityCheckEvent('processing'));

  return Services.PaymentService.checkCardEligibility(
    accountNumber,
    getState().enrollment.device,
  ).then(
    (resp) => {
      onSuccess(resp.data);
      dispatch(setCardConfig(resp.data.config));
      dispatch(cardEligibilityCheckEvent('success'));
    },
    (error) => {
      handleErrorResponse(error, onError);
      dispatch(cardEligibilityCheckEvent('error'));
    },
  );
};

export const cardDetailsCheckAction = (cardDetails, onSuccess, onError) => (
  dispatch,
  getState,
) => {
  dispatch(cardEligibilityCheckEvent('processing'));

  const { cardConfig } = getState().enrollment;

  if (cardConfig.addressRequired === false) {
    delete cardDetails.billingAddress;
  }

  if (cardConfig.cardholderNameRequired === false) {
    delete cardDetails.cardholderName;
  }

  if (cardConfig.cvcRequired === false) {
    delete cardDetails.securityCode;
  }

  return Services.PaymentService.checkCardDetails(
    cardDetails,
    getState().enrollment.device,
  ).then(
    (resp) => {
      onSuccess(resp.data);
      dispatch(cardEligibilityCheckEvent('success', resp.data));
    },
    (error) => {
      if (error.response.data) {
        handleErrorResponse(error, onError);
      } else {
        onError();
      }
      dispatch(cardEligibilityCheckEvent('error'));
    },
  );
};

export const onEnrollAction = (
  cardDetails,
  eligibility,
  onSuccess,
  onError,
) => (dispatch, getState) => {
  eligibility.userEmail = getState().security.user;

  const { cardConfig } = getState().enrollment;

  if (cardConfig.addressRequired === false) {
    delete cardDetails.billingAddress;
  }

  if (cardConfig.cardholderNameRequired === false) {
    delete cardDetails.cardholderName;
  }

  if (cardConfig.cvcRequired === false) {
    delete cardDetails.securityCode;
  }

  return Services.PaymentService.enrol(
    cardDetails,
    eligibility,
    getState().enrollment.device,
  ).then(
    (resp) => {
      onSuccess(resp.data);
    },
    (error) => {
      handleErrorResponse(error, onError);
    },
  );
};
