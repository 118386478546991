import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { withTranslation } from '../../config/i18n';
import EcommFooter from '../../components/ecomm-integration-footer/EcommFooter';

import styles from './styles';

const FinalConfirmation = ({ handleBack, handleFinish, classes, t }) => {
  const security = useSelector((state) => state.security);
  const enrollment = useSelector((state) => state.enrollment);

  const {
    variants,
    defaultImage,
    model,
    modelDisplayName,
  } = security.deviceInfo;

  const hasVariants = variants && variants.length > 0;

  const imageURI =
    hasVariants && variants[0].image ? variants[0].image : defaultImage;

  const variantName = hasVariants && variants[0].color;

  return (
    <div className={classes.mainContainer}>
      <p className={classes.header}>
        <strong>{t('finalConfirmation.header')}</strong>
      </p>

      <div className={classes.detailsContainer}>
        <div>
          <img className={classes.wearableImage} alt="device" src={imageURI} />
        </div>
        <div>
          <p>
            {t('finalConfirmation.wearable')}{' '}
            <strong>{`${
              modelDisplayName || model?.name?.replace(/_/g, ' ') || ''
            } ${variantName?.replace(/_/g, ' ') || ''}`}</strong>
          </p>
          <p>
            {t('finalConfirmation.linkedCard')}{' '}
            <strong>{`****${enrollment.card?.accountNumber.slice(-4)}`}</strong>{' '}
            {t('finalConfirmation.cannotBeChanged')}
          </p>
          <p>
            {t('finalConfirmation.linkedEmail')}{' '}
            <strong>{security.user}</strong>
          </p>
        </div>
      </div>
      <br />
      {!security.alreadyExists &&
        enrollment.cardConfig.emailSender === 'Manage-Mii' && (
          <div className={classes.manageMiiDetails}>
            <p>
              {t('finalConfirmation.activationLink', { email: security.user })}
            </p>
            <p>
              {' '}
              <strong className={classes.highlighted}>
                {t('finalConfirmation.checkSpam')}
              </strong>
            </p>
          </div>
        )}
      <EcommFooter
        onPrev={handleBack}
        onNext={handleFinish}
        nextType="finish"
      />
    </div>
  );
};

FinalConfirmation.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(withStyles(styles)(FinalConfirmation));
