const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    minHeight: '350px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: { fontSize: 18 },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wearableImage: {
    height: '100px',
    width: '100px',
    objectFit: 'contain',
    margin: '5px 10px',
  },
  manageMiiDetails: { width: '90%', textAlign: 'center' },
  highlighted: {
    color: '#FF3F13',
  },
});

export default styles;
