const styles = {
  iframe: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minWidth: '320px',
    maxWidth: '800px',
  },
  iframeError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    height: '100%',
    width: '100%',
  },
  loading: {
    minHeight: '600px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingCircularProgress: {
    color: 'black',
  },
};

export default styles;
