import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from '../../config/i18n';
import { oemName } from '../../utils';
import styles from './styles';

const OrderConfirmationPage = ({
  navigationComponentProps,
  navigationComponent,
  classes,
  deviceInfo,
  security,
  t,
}) => {
  const { variants, defaultImage, model, modelDisplayName } = deviceInfo;

  const hasVariants = variants && variants.length > 0;

  const imageURI =
    hasVariants && variants[0].image ? variants[0].image : defaultImage;

  const variantName = hasVariants && variants[0].color;

  const NavigationComponent = navigationComponent;
  return (
    <div className={classes.mainContainer}>
      <div className={classes.orderConfirmationContent}>
        <div className={classes.header}>
          <Typography variant="h6" gutterBottom>
            {t('orderConfirmation.header')}
          </Typography>
        </div>
        <div className={classes.deviceInfoContainer}>
          <img className={classes.img} alt="device" src={imageURI} />
          <div className={classes.deviceDetails}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.deviceModel}
            >
              {modelDisplayName || model.name.replace(/_/g, ' ')}
            </Typography>
            {variantName && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.deviceVariant}
              >
                {variantName.replace(/_/g, ' ')}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.subheader}>
          <Typography variant="body2" gutterBottom>
            {' '}
            {t('orderConfirmation.subheader1')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('orderConfirmation.subheader2', {
              oem: oemName(security.clientName),
            })}
          </Typography>
        </div>
      </div>

      <div>
        {NavigationComponent && (
          <NavigationComponent {...navigationComponentProps} />
        )}
      </div>
    </div>
  );
};

OrderConfirmationPage.propTypes = {
  navigationComponentProps: PropTypes.shape({
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.object,
  }).isRequired,
  deviceInfo: PropTypes.shape({
    variants: PropTypes.array,
    defaultImage: PropTypes.string,
    model: PropTypes.object.isRequired,
    brand: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(
  withTheme(withTranslation(withStyles(styles)(OrderConfirmationPage))),
);
