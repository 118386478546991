const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '580px',
    margin: '10px 0',
    '@media (max-width: 425px)': {
      width: '90%',
      margin: 'auto',
    },
  },
  publicKeyError: {
    margin: 'auto',
    textAlign: 'center',
    width: '90%',
  },
  stepper: {
    // IE11 FIX
    width: '75%',
    '@media (max-width: 425px)': {
      width: '100%',
    },
  },
};

export default styles;
