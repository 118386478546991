const styles = (theme) => ({
  cardEligibilityMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  cardEligibilityContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '300px',
  },
  creditCardWithEligibility: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: '263px',
    alignItems: 'flex-start',
  },
  creditCardInputAndImage: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  helpLinksContainer: {
    alignSelf: 'center',
    width: '258px',
  },
  creditCardImage: {
    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      display: 'none',
    },
  },
  cardImageTextInfo: {
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  genericPaymentCard: {
    maxHeight: '188px',
  },
  navigationFooter: {
    width: '100%',
  },
  creditCardFieldWithValidation: {
    width: '258px',
    alignSelf: 'center',
  },
  creditCardHeaderText: {
    color: theme.palette.text.primary,
    fontSize: '20px',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
});

export default styles;
