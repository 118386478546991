const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minHeight: '600px',
    textAlign: 'center',
    maxWidth: '80%',
    margin: 'auto',
    '@media (max-width: 425px)': {
      maxWidth: '100%',
    },
  },
  headerText: {
    color: theme.palette.text.primary,
    width: '80%',
    '@media (max-width: 400px)': {
      width: '90%',
    },
  },
  managedByContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    '@media (max-width: 400px)': {
      flexDirection: 'column',
    },
  },
  manageByImg: {
    marginBottom: 5,
    height: '25px',
    padding: 5,
  },
});

export default styles;
