const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background,
  },
  menuItemSeparator: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
});

export default styles;
