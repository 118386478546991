import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule, translate } from 'react-i18next';

const supportedLanguages = ['en', 'nl', 'fr', 'de', 'ru'];
i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    debug: false,
    load: 'languageOnly',
    fallbackLng: 'en',
    whitelist: [...supportedLanguages],
    preload: false,
    crossDomain: true,
    interpolation: {
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
  });

const withTranslation = (page) =>
  translate(['translation'], {
    wait: true,
  })(page);

export { i18next, withTranslation };
