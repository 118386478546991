export default {
  palette: {
    primary: {
      main: '#174f81',
    },
    text: { primary: '#333' },
    background: '#FFF',
    action: {
      hover: '#444',
      selected: '#444',
    },
  },
};
