import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    fontSize: '0.9rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    backgroundColor: `${theme.palette.background} !important`,
  },
});

class SelectBank extends React.Component {
  state = {
    bank: 'https://www.abnamro.nl/wearable',
  };

  handleChange = (event) => {
    const { handleSelectBank } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    handleSelectBank(event.target.value);
  };

  render() {
    const { classes } = this.props;
    const { bank } = this.state;

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            value={bank}
            onChange={this.handleChange}
            inputProps={{
              name: 'bank',
              id: 'select-bank',
            }}
          >
            <MenuItem
              className={classes.menuItem}
              value="https://www.abnamro.nl/wearable"
            >
              ABN AMRO
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

SelectBank.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(SelectBank);
