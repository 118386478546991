const styles = {
  signupAlternativeMaincontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '10px 0',
    width: '80%',
    '@media (max-width: 400px)': {
      width: '90%',
    },
  },

  error: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepper:{  // IE11 FIX
    width: "75%",
    '@media (max-width: 425px)': {
      width: '100%',
    },
  }
};

export default styles;
