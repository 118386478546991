const styles = (theme) => ({
  outlinedPrimary: {
    background: theme.palette.background,
    '&:hover': {
      background: theme.palette.background,
    },
    borderRadius: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: 40,
    padding: '0 30px',
  },
  textSecondary: {
    border: 0,
  },
  sizeSmall: {
    width: 100,
  },
});

export default styles;
