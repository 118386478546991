const styles = {
  ecommFooter: {
    margin: ' 5px auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    width: '600px',
    '@media (max-width: 600px)': {
      width: '90%',
      margin: 'auto',
    },
  },
  footerButtons: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
  },
};

export default styles;
