export default {
  palette: {
    type: 'light',
    primary: {
      main: '#277df4',
    },
    text: {
      primary: '#001232',
    },
    background: '#fff',
    action: {
      hover: '#ddd',
      selected: '#ccc',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
};
