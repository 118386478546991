import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from './styles';

const StyledButton = ({
  classes,
  children,
  small,
  secondary,
  ...otherProps
}) => {
  const className = `${classes.outlinedPrimary} ${small &&
    classes.sizeSmall} ${secondary && classes.textSecondary}`;

  return (
    <Button className={className} {...otherProps}>
      {children}
    </Button>
  );
};

StyledButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
};

StyledButton.defaultProps = {
  small: false,
  secondary: false,
};

export default withStyles(styles)(StyledButton);
