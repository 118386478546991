import { SET_QUERY_PARAMS } from './ActionTypes';
import { setClientId } from './SecurityActions';
import { setDevice } from './EnrollmentActions';
import { buildDeviceFromParams } from '../../utils';

const processQueryParams = (queryParams) => (dispatch) => {
  dispatch({
    type: SET_QUERY_PARAMS,
    queryParams,
  });
  dispatch(setClientId(queryParams.clientId));
  dispatch(setDevice(buildDeviceFromParams(queryParams)));
};

export default processQueryParams;
