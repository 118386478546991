export default {
  palette: {
    primary: {
      main: '#d82d20',
    },
    text: { primary: '#333' },
    background: '#ffffff',
    action: {
      hover: '#d82d20',
      selected: '#d82d20',
    },
  },
};
