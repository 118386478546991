const styles = (theme) => ({
  noPanHelpContainer: {
    fontSize: '15px',
    textAlign: 'center',
    minHeight: '114px',
    maxWidth: '80%',
    margin: 'auto',
    alignSelf:"center" //IE FIX
  },
  noPanSelectBank: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
});

export default styles;
