import {
  SIGN_IN,
  SIGN_OUT,
  SET_CLIENT_NAME,
  SET_CLIENT_ID,
  SET_DEVICE_VALID,
  SET_DEVICE_INFO,
  SET_USER,
  GET_USER,
} from '../actions/ActionTypes';

const defaultSecurityState = {
  isLoggedIn: localStorage.getItem('token') !== null,
  user: undefined,
  alreadyExists: undefined,
  isProcessing: false,
  redirectToReferrer: false,
  clientName: undefined,
  isDeviceValid: undefined,
  deviceInfo: undefined,
};

export default (state = defaultSecurityState, action) => {
  const isProcessing = action.status === 'processing';
  const redirectToReferrer = action.status === 'success';

  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isProcessing,
        redirectToReferrer,
        isLoggedIn: true,
      };
    case SIGN_OUT:
      localStorage.removeItem('token');
      return {
        ...state,
        isLoggedIn: false,
        redirectToReferrer: false,
      };

    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      };
    case SET_CLIENT_NAME:
      return {
        ...state,
        isProcessing,
        clientName: action.clientName,
      };
    case SET_DEVICE_VALID:
      return {
        ...state,
        isDeviceValid: action.isValid,
      };
    case SET_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.device,
      };
    case GET_USER:
      return {
        ...state,
        isProcessing,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
        alreadyExists: action.alreadyExists,
      };
    default:
      return state;
  }
};
