export default {
  palette: {
    type: 'light',
    primary: { main: '#0274be' },
    text: {
      primary: '#333',
    },
    background: '#fff',
    action: {
      hover: '#e1e1e1',
      selected: '#b8cbe0',
    },
  },
};
