export default {
  palette: {
    primary: {
      main: '#111111',
    },
    text: { primary: '#333' },
    background: '#FFF',
    action: {
      hover: '#30D3D2',
      selected: '#30D3D2',
    },
  },
};
