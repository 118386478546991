import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import { withTranslation } from '../../config/i18n';
import { validateFields } from '../../utils';

import {
  signUpAction,
  signUpCompletedAction,
} from '../../state/actions/SignUpActions';

import styles from './styles';

const renderHelpText = (error, field, style) => {
  if (typeof error !== 'undefined' && error !== '') {
    return (
      <FormHelperText error id={`${field}-error-text`} style={style}>
        {error}
      </FormHelperText>
    );
  }
  return null;
};

const formValidationContraints = (t) => ({
  email: {
    email: {
      message: t('signUp.emailFormatError'),
    },
    length: {
      maximum: 256,
      message: t('signUp.emailLengthError'),
    },
  },
  password: {
    length: {
      minimum: 8,
      maximum: 16,
      message: t('signUp.passwordLengthError'),
    },
  },
  confirmPassword: {
    length: {
      minimum: 8,
      maximum: 16,
      message: t('signUp.confirmPasswordLengthError'),
    },
    equality: {
      attribute: 'password',
      message: t('signUp.confirmPasswordMismatchError'),
    },
  },
});

class SignUp extends Component {
  static propTypes = {
    onSignUpClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.shape({}).isRequired,
    routerState: PropTypes.shape({}).isRequired,
  };

  state = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    confirmPassword: '',
    confirmPasswordError: '',
    showSuccessMessage: false,
  };

  componentDidUpdate(prevProps) {
    const { signUp } = this.props;

    if (prevProps.signUp.success !== signUp.success && signUp.success) {
      this.setState({
        showSuccessMessage: true,
      });
    }
  }

  validateControls = () => {
    const {
      email,
      password,
      confirmPassword,
      emailError,
      passwordError,
      confirmPasswordError,
    } = this.state;
    const { t } = this.props;
    const constraints = formValidationContraints(t);

    return validateFields(
      { email, password, confirmPassword },
      constraints,
      { emailError, passwordError, confirmPasswordError },
      (errors) => this.setState({ ...errors }),
    );
  };

  handleConfirmationClose = () => {
    const { onNavigateToSignIn, routerState } = this.props;
    onNavigateToSignIn(routerState);
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailError: '',
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
      passwordError: '',
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordError: '',
    });
  };

  register = () => {
    const { onSignUpClick, isFetching, routerState } = this.props;

    const isValidForm = this.validateControls();
    if (isValidForm && !isFetching) {
      const { email, password } = this.state;
      onSignUpClick(email, password, routerState);
    }
  };

  finishRegistration = () => {
    const { onSignUpCompleted, routerState } = this.props;
    onSignUpCompleted(routerState);
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { signUp, theme, classes, t } = this.props;
    const { isProcessing, errorCode } = signUp;
    const {
      emailError,
      passwordError,
      confirmPasswordError,
      showSuccessMessage,
    } = this.state;

    if (showSuccessMessage) {
      const { email } = this.state;
      return (
        <div className={classes.signUpConfirmation}>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="center"
          >
            <Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="center"
                >
                  <Typography variant="h3">
                    {t('signUp.successTitle')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('signUp.successMessage', {
                      email,
                    })}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('signUp.successActionMessage')}
                  </Typography>
                </Grid>
                <br />
                <Button
                  onClick={this.finishRegistration}
                  color="primary"
                  variant="outlined"
                >
                  {t('button.ok')}{' '}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Card>
          <Typography
            variant="h6"
            style={{ color: theme.palette.text.primary }}
          >
            {t('signUp.title')}
          </Typography>
          {errorCode &&
            renderHelpText(
              t([
                `signUp.errors.${errorCode}`,
                'generalError.somethingWentWrong',
              ]),
              'errorCode',
              { textAlign: 'center' },
            )}
          <form noValidate autoComplete="off">
            <FormControl className={classes.signUpSignUpControl}>
              <TextField
                disabled={isProcessing}
                id="email"
                label={t('signUp.email')}
                error={emailError !== ''}
                placeholder={t('signUp.emailPlaceholder')}
                margin="none"
                fullWidth
                onChange={this.handleEmailChange}
              />
              {renderHelpText(emailError, 'email')}
            </FormControl>
            <FormControl className={classes.signUpSignUpControl}>
              <TextField
                disabled={isProcessing}
                id="password"
                label={t('signUp.password')}
                error={passwordError !== ''}
                placeholder={t('signUp.passwordPlaceholder')}
                type="password"
                margin="none"
                fullWidth
                onChange={this.handlePasswordChange}
              />
              {renderHelpText(passwordError, 'password')}
            </FormControl>
            <FormControl className={classes.signUpSignUpControl}>
              <TextField
                disabled={isProcessing}
                id="confirmPassword"
                label={t('signUp.confirmPassword')}
                error={confirmPasswordError !== ''}
                placeholder={t('signUp.confirmPasswordPlaceholder')}
                type="password"
                margin="none"
                fullWidth
                onChange={this.handleConfirmPasswordChange}
              />
              {renderHelpText(confirmPasswordError, 'confirmPassword')}
            </FormControl>
            <FormControl className={classes.signUpSignUpControl}>
              <Button
                disabled={isProcessing}
                onClick={this.register}
                color="primary"
                variant="outlined"
              >
                {isProcessing
                  ? t('signUp.pleaseWaitButtonLabel')
                  : t('signUp.createAccountButtonLabel')}{' '}
              </Button>
              <Button onClick={this.onBack} color="default" variant="outlined">
                {t('button.back')}
              </Button>
            </FormControl>
          </form>
          <br />
          <Typography className={classes.tcLabel} variant="caption">
            {t('signUp.termsAndConditionsMessage')}
            <br />
            <a
              style={{ color: theme.palette.text.primary }}
              href={t('signUp.termsAndConditionsUrl')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('signUp.termsAndConditions')}
            </a>
          </Typography>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  signUp: state.signUp,
  routerState: ownProps.location.state,
});

const mapDispatchToProps = (dispatch) => ({
  onSignUpClick: (username, password) =>
    dispatch(signUpAction({ username, password })),
  onSignUpCompleted: (routerState) =>
    dispatch(signUpCompletedAction(routerState)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(withTheme(withStyles(styles)(SignUp))));
