const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '400px',
    width: '100%',
  },
  inputContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '150px',
  },
  inputEmail: {
    width: '310px',
  },
  headerTextContainer: { width: '90%' },
  subheader: {
    color: theme.palette.text.primary,
    fontSize: '15px',
  },
  header: {
    color: theme.palette.text.primary,
    fontSize: '22px',
  },
  buttonsContainer: {
    width: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    '@media (max-width: 600px)': {
      width: '90%',
      margin: '10px auto',
    },
  },
  loadingContainer: {
    minHeight: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
