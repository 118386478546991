import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const SignUpTerms = ({ t, classes }) => (
  <Typography variant="caption">
    {t('signUp.termsAndConditionsMessage')}{' '}
    <a
      className={classes.link}
      href={t('signUp.termsAndConditionsUrl')}
      rel="noopener noreferrer"
      target="_blank"
    >
      {t('signUp.termsAndConditions')}{' '}
    </a>
  </Typography>
);

SignUpTerms.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(withStyles(styles)(SignUpTerms));
