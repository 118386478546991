import digiseq from './digiseqTheme';
import laks from './laksTheme';
import k from './kTheme';
import kiroco from './kirocoTheme';
import mondaine from './mondaineTheme';
import pda from './pdaTheme';
import rosan from './rosanDiamondTheme';
import usc from './usc';
import winwatch from './winwatchTheme';
import demo from './demoTheme';
import tovisorga from './tovisorgaTheme';

const themes = {
  digiseq,
  laks,
  k,
  kiroco,
  mondaine,
  pda,
  rosan,
  usc,
  winwatch,
  demo,
  'tovi sorga': tovisorga,
};
export default themes;
