import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, MuiThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';

import Routes from './routes';
import theme from './config/themes/theme';

const AppContainer = ({ security }) => {
  const { clientName } = security;
  const clientTheme = theme(clientName);

  const style = {
    backgroundColor: clientTheme.palette.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div id="app-routes" style={style}>
      <MuiThemeProvider theme={clientTheme}>
        <Routes />
      </MuiThemeProvider>
    </div>
  );
};

AppContainer.propTypes = {
  security: PropTypes.shape({
    clientName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  error: state.error,
});

export default connect(mapStateToProps)(withTheme(AppContainer));
