import React, { Component } from 'react';
import JsBarcode from 'jsbarcode';
import { withTranslation } from '../../config/i18n';
import './home.css';

class HomePage extends Component {
  state = {
    showBarcode: false,
  };

  componentDidMount() {
    window.addEventListener('message', (e) => {
      if (e.data.actionType === 'ENROLLMENT_SUCCESS') {
        this.setState({ showBarcode: true });
        JsBarcode('#barcode', e.data.payload);
      }
    });
  }

  render() {
    const baseUrl = window.location.href;
    const { showBarcode } = this.state;

    return (
      <div className="App resp-iframe-container">
        <iframe
          className="resp-iframe"
          src={`${baseUrl}ecommerce?clientId=44cd94b6-ab18-649d-b64d-0170d04169ab&brand=kerv&model=ring&version=1&description=black jet`}
          title="Digiseq Ecommerce Integration"
          frameBorder="0"
        />
        {showBarcode && <svg id="barcode" />}
      </div>
    );
  }
}

export default withTranslation(HomePage);
