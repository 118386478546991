import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { withTranslation } from '../../config/i18n';

import { CreditCardField } from '../../components/credit-card';
import HelpText from '../../components/help-text';
import PanHelp from './pan-help/PanHelp';

import { iframeResponse } from '../../utils';

import genericPaymentCard from './img/generic-bank-card.png';

import styles from './styles';

export class CreditCardWithEligibilityCheck extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onCheck: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    setIsCardValid: PropTypes.func.isRequired,
    navigationComponentProps: PropTypes.shape({
      onNext: PropTypes.func.isRequired,
      onPrev: PropTypes.func,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      cardAccountNumber: value,
      accountNumberError: '',
      showError: false,
      isMastercard: false,
      showSignUp: false,
      showWhatIsPan: false,
      bank:
        'https://www.abnamro.nl/portalserver/mijn-abnamro/betalen/wearables/index.html',
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleOnBack = () => {
    this.setState({ showSignUp: true });
  };

  handleEligibilityCheck = () => {
    const { cardAccountNumber } = this.state;
    const {
      navigationComponentProps,
      onCheck,
      isFetching,
      t,
      cardValidity,
    } = this.props;

    if (!cardValidity) {
      this.setState({
        showError: true,
        accountNumberError: t('components.creditCard.cardNumberNotValid'),
      });
      return;
    }

    if (!isFetching) {
      onCheck(
        cardAccountNumber,
        () => {
          if (typeof navigationComponentProps !== 'undefined') {
            navigationComponentProps.onNext(cardAccountNumber);
          }
        },
        (error) => {
          this.setState({
            showError: true,
            accountNumberError: error,
          });
        },
      );
    }
  };

  // TODO remove hack when implemented in core
  isKBCCreditCard = () => {
    const { cardAccountNumber } = this.state;

    const unsupportedBinRanges = [
      '527583',
      '544038',
      '547027',
      '517651',
      '529482',
      '547835',
      '536496',
      '522348',
      '527618',
      '544050',
      '547033',
      '547837',
      '538900',
      '522354',
    ];
    const binRange = cardAccountNumber.substring(0, 6);

    if (unsupportedBinRanges.includes(binRange)) return true;

    return false;
  };

  handleCardAccountNumberChange = (event) => {
    const { handleUpdate, setIsCardValid } = this.props;
    handleUpdate(event.value);

    if (event.type === 'Mastercard' || event.type === 'Maestro') {
      setIsCardValid(event.valid);
      this.setState({ isMastercard: true });
    } else {
      setIsCardValid(false);
      this.setState({ isMastercard: false });
    }

    this.setState({
      cardAccountNumber: event.value,
      accountNumberError: '',
      showError: false,
    });
  };

  handleSelectBank = (bank) => {
    this.setState({ bank });
  };

  handleWhatIsPan = () => {
    const { showWhatIsPan } = this.state;

    if (!showWhatIsPan) {
      this.setState({ showWhatIsPan: true });
      this.timer = setTimeout(() => {
        this.setState({
          showWhatIsPan: false,
        });
      }, 10000);
    }
  };

  handleGetPan = () => {
    const { bank } = this.state;
    iframeResponse('NOPAN', bank);
  };

  render() {
    const {
      cardAccountNumber,
      accountNumberError,
      showError,
      isMastercard,
      showWhatIsPan,
    } = this.state;
    const {
      navigationComponentProps,
      navigationComponent,
      isFetching,
      theme,
      t,
      user,
    } = this.props;
    const NavigationComponent = navigationComponent;

    const { classes, ...propsWithoutClasses } = this.props;

    return (
      <div
        data-test="main-container"
        className={classes.cardEligibilityMainContainer}
      >
        <div className={classes.cardEligibilityContent}>
          <div className={classes.creditCardInputAndImage}>
            <div className={classes.creditCardWithEligibility}>
              <div className={classes.creditCardFieldWithValidation}>
                <Typography
                  variant="body1"
                  className={classes.creditCardHeaderText}
                  color="primary"
                >
                  {t('components.creditCard.headerText')}
                </Typography>
                <CreditCardField
                  data-test="creditcardfield"
                  accountNumber={cardAccountNumber}
                  fullWidth
                  onPanSubmitted={this.handleEligibilityCheck}
                  onError={(error) => this.setState({ ...error })}
                  error={showError && accountNumberError !== ''}
                  onChange={this.handleCardAccountNumberChange}
                  {...propsWithoutClasses}
                  InputLabelProps={{ shrink: true, hidden: true }}
                  endAdornment={
                    <InputAdornment position="end">
                      {isFetching && <CircularProgress size={18} />}
                    </InputAdornment>
                  }
                />
                {showError && isMastercard && (
                  <HelpText
                    data-test="pan-error"
                    error={
                      accountNumberError.includes('1016') &&
                      this.isKBCCreditCard()
                        ? t('components.creditCard.kbcCreditCardNotEligibile')
                        : accountNumberError
                    }
                    field="card-number-error-text"
                  />
                )}
                {!isMastercard && showError && (
                  <HelpText
                    data-test="cardnoteligible"
                    error={t('components.creditCard.cardNumberNotEligible')}
                    field="card-number-error-text"
                  />
                )}
              </div>
              <div className={classes.helpLinksContainer}>
                <Typography
                  data-test="what-is-pan"
                  variant="subtitle2"
                  className={classes.link}
                  color="primary"
                  onClick={() => this.handleWhatIsPan()}
                  gutterBottom
                >
                  {t('noPanFlow.whatCardNumber')}
                </Typography>
              </div>
            </div>
            <div className={classes.creditCardImage}>
              <img
                data-test="card-image"
                src={genericPaymentCard}
                alt="generic-payment-card"
                className={classes.genericPaymentCard}
              />
              <div className={classes.cardImageTextInfo}>
                <Typography
                  data-test="card-image-text-info"
                  variant="body2"
                  gutterBottom
                >
                  {t('components.creditCard.cardImageTextInfo')}
                </Typography>
              </div>
            </div>
          </div>
          <PanHelp
            data-test="panhelp"
            showWhatIsPan={showWhatIsPan}
            theme={theme}
            t={t}
            onGetPan={this.handleGetPan}
            onSelectBank={this.handleSelectBank}
          />
        </div>
        <div data-test="navigation-footer" className={classes.navigationFooter}>
          {NavigationComponent && (
            <NavigationComponent
              data-test="navigation-component"
              {...navigationComponentProps}
              user={user}
              onBack={this.handleOnBack}
              onNext={this.handleEligibilityCheck}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation(
  withTheme(withStyles(styles)(CreditCardWithEligibilityCheck)),
);
