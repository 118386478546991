import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Step from '@material-ui/core/Step';

import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withTranslation } from '../../config/i18n';
import SignUpEmailForm from './sign-up-email-form/SignUpEmailForm';
import HelpText from '../../components/help-text/HelpText';
import IframeFooter from '../../components/iframe-footer/IframeFooter';
import Logo from '../../components/logo';
import FinalConfirmation from '../final-confirmation/FinalConfirmation';

import {
  setUserEvent,
  getUserAction,
} from '../../state/actions/SecurityActions';

import { iframeResponse } from '../../utils';

import styles from './styles';

class SignUpAlternative extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { iframe } = props;

    this.state = {
      enterEmailManually: !iframe.email,
      showConfirmationPage: false,
      activeStep: 1,
    };
  }

  onFinish = () => {
    const { history } = this.props;
    iframeResponse('ENROLLING');
    history.replace('/enrolled');
  };

  onEmailCollected = (email, alreadyExists) => {
    const { onSetUser } = this.props;
    onSetUser(email, alreadyExists);
    this.setState({ showConfirmationPage: true, activeStep: 2 });
  };

  onBack = () => {
    const { history } = this.props;
    const { activeStep } = this.state;

    if (activeStep === 1) {
      history.replace('/credit-card-registration');
    } else {
      this.setState({ showConfirmationPage: false, activeStep: 1 });
      history.replace('/signup-alternative');
    }
  };

  showSignUpForm = (showForm) => {
    this.setState({
      enterEmailManually: showForm,
    });
  };

  render() {
    const {
      signUp,
      security,
      enrollment,
      onCheckUserExists,
      iframe,
      classes,
      t,
    } = this.props;

    const { enterEmailManually, activeStep, showConfirmationPage } = this.state;
    const { errorCode, isProcessing } = signUp;
    const { cardConfig } = enrollment;

    const steps = t('cardRegistration.steps', {
      returnObjects: true,
    });

    const stepperLabels = Object.keys(steps).reduce(
      (acc, e) => [...acc, steps[e]],
      [],
    );

    return (
      <div className={classes.signupAlternativeMaincontainer}>
        <Logo height={40} width={80} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {errorCode && (
            <div className={classes.error}>
              <HelpText
                error={t([
                  `signUp.errors.${errorCode}`,
                  'generalError.somethingWentWrong',
                ])}
                field="errorCode"
              />
            </div>
          )}
          
            <Stepper
              data-test="stepper"
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {stepperLabels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          

          {!showConfirmationPage ? (
            <SignUpEmailForm
              cardConfig={cardConfig}
              t={t}
              email={iframe.email}
              isProcessing={isProcessing || security.isProcessing}
              handleEmailCollected={(email, alreadyExists) =>
                this.onEmailCollected(email, alreadyExists)
              }
              isEmailFromParam={!enterEmailManually}
              enterManuallyClicked={() => this.showSignUpForm(true)}
              onCheckUserExists={onCheckUserExists}
              backPress={this.onBack}
            />
          ) : (
            <FinalConfirmation
              handleBack={this.onBack}
              handleFinish={this.onFinish}
              deviceInfo={security.deviceInfo}
              t={t}
            />
          )}
        </div>
        {!showConfirmationPage && <IframeFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  signUp: state.signUp,
  security: state.security,
  iframe: state.iframe,
  enrollment: state.enrollment,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (email, alreadyExists) =>
    dispatch(setUserEvent(email, alreadyExists)),
  onCheckUserExists: (username, onSuccess, onError) =>
    dispatch(getUserAction(username, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(withStyles(styles)(SignUpAlternative)));
