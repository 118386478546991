import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import laks from './images/LaksPay.png';
import k from './images/KLogo.svg';
import mondaine from './images/MondaineLogo.png';
import rosan from './images/RosanPayLogo.png';
import pda from './images/PdaLogo.png';
import kiroco from './images/kPayLogo.png';
import winwatch from './images/winwatchLogo.png';
import tovisorga from './images/tovisorgaLogo.png';
import digiseq from './images/DigiseqLogo.jpg';

const logoUrl = (oemName) => {
  switch (oemName) {
    case 'laks':
      return laks;
    case 'k':
      return k;
    case 'mondaine':
      return mondaine;
    case 'rosan':
      return rosan;
    case 'pda':
      return pda;
    case 'kiroco':
      return kiroco;
    case 'winwatch':
      return winwatch;
    case 'tovi sorga':
      return tovisorga;
    default:
      return digiseq;
  }
};

const Logo = ({ height, width, security }) => {
  const logoContainerStyle = {
    height,
    width,
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${logoUrl(security.clientName)})`,
  };

  return <div style={logoContainerStyle} />;
};

Logo.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  security: PropTypes.shape({
    clientName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(Logo);
