import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import { withTranslation } from '../../config/i18n';
import { signOutEvent } from '../../state/actions/SecurityActions';

import Logo from '../logo';

const EcommHeader = () => {
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justify="space-between"
    >
      <Logo height={40} width={80} />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { isLoggedIn } = state.security;
  return { isLoggedIn };
};

const mapDispatchToProps = (dispatch) => ({
  onSignOutClick: () => dispatch(signOutEvent()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(EcommHeader));
