import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { set_configuration as setConfiguration } from 'mp-common-js';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withTranslation } from '../config/i18n';
import { ENV } from '../env';

import {
  setClientNameEvent,
  validateDeviceAction,
  signOutEvent,
} from '../state/actions/SecurityActions';
import processQueryParams from '../state/actions/iFrameActions';

import {
  isTokenExpired,
  buildDeviceFromParams,
  iframeResponse,
} from '../utils';

import styles from './styles';

class Iframe extends React.Component {
  state = {
    loadingError: undefined,
    isLoading: false,
  };

  async componentDidMount() {
    const {
      security,
      t,
      params,
      onSignOutEvent,
      onProcessQueryParams,
      setClientName,
      onValidateDevice,
    } = this.props;
    const { clientName } = security;
    const token = localStorage.getItem('token');

    if (token && isTokenExpired(token)) {
      onSignOutEvent();
    }

    try {
      if (!clientName) {
        this.setState({ isLoading: true });
        onProcessQueryParams(params);
        if (params.lang) i18next.changeLanguage(params.lang);

        const config = await setConfiguration({
          clientId: params.clientId,
          type: 'web',
          environment: ENV,
        });

        if (!config.clientName)
          this.setState({ loadingError: t('reg.noClientId') });

        setClientName(config.clientName);

        await onValidateDevice(buildDeviceFromParams(params));

        this.setState({ isLoading: false });
        iframeResponse('IFRAME_LOADED');
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.data.message) {
          case 'Client not found':
            this.setState({
              loadingError: t('reg.noClientId'),
              isLoading: false,
            });
            iframeResponse('ERROR', err.response.data.message);
            break;
          case 'Network Error':
            this.setState({
              loadingError: t('reg.networkError'),
              isLoading: false,
            });
            iframeResponse('ERROR', err.response.data.message);
            break;
          default:
            this.setState({
              loadingError: err.response.message,
              isLoading: false,
            });
            iframeResponse('ERROR', err.response.data.message);
        }
      } else {
        this.setState({
          loadingError: t('reg.networkError'),
          isLoading: false,
        });
        iframeResponse('ERROR', t('reg.networkError'));
      }
    }
  }

  componentDidUpdate() {
    const { t, security } = this.props;
    const { loadingError } = this.state;

    if (!loadingError && security.isDeviceValid === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loadingError: t('reg.deviceNotValid'),
      });
    }
  }

  render() {
    const { component: Component, classes, props } = this.props;
    const { loadingError, isLoading } = this.state;

    if (isLoading)
      return (
        <div className={classes.loading}>
          <CircularProgress
            className={classes.loadingCircularProgress}
            size={60}
            thickness={5}
          />
        </div>
      );

    return (
      <div className={classes.iframe}>
        {loadingError ? (
          <div className={classes.iframeError}>
            <h1>{loadingError}</h1>
          </div>
        ) : (
          <Component {...props} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  enrollment: state.enrollment,
});

const mapDispatchToProps = (dispatch) => ({
  setClientName: (clientName) => dispatch(setClientNameEvent(clientName)),
  onProcessQueryParams: (params) => dispatch(processQueryParams(params)),
  onValidateDevice: (device) => dispatch(validateDeviceAction(device)),
  onSignOutEvent: () => dispatch(signOutEvent()),
});

export default withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Iframe)),
);
