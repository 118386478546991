import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { Number } from 'react-credit-card-primitives';
import { withTranslation } from '../../config/i18n';

import styles from './styles';

const CreditCardField = ({
  accountNumber,
  onChange,
  onPanSubmitted,
  readOnly,
  error,
  fullWidth,
  style,
  InputLabelProps,
  endAdornment,
  margin,
  t,
}) => {
  const controlStyle = fullWidth ? { ...style, width: '100%' } : { ...style };

  const ccFormat = (value) => (value.match(/.{1,4}/g) || []).join(' ');

  return (
    <FormControl style={controlStyle} margin={margin}>
      <InputLabel {...InputLabelProps} htmlFor="card-number">
        {t('components.creditCard.label')}
      </InputLabel>
      <Number
        onChange={onChange}
        render={({ getInputProps }) => (
          <Input
            {...getInputProps()}
            {...{ error, fullWidth, endAdornment }}
            placeholder={t('components.creditCard.placeholder')}
            id="card-number"
            value={ccFormat(accountNumber)}
            onKeyPress={(e) => (e.key === 'Enter' ? onPanSubmitted() : null)}
            disabled={readOnly}
            autoFocus
            autoComplete="off"
            inputProps={{
              maxLength: 23,
            }}
          />
        )}
      />
    </FormControl>
  );
};

CreditCardField.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  onError: PropTypes.func,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool.isRequired,
  onPanSubmitted: PropTypes.func,
  onChange: PropTypes.func,
};

CreditCardField.defaultProps = {
  readOnly: false,
  onError: undefined,
  error: false,
  onPanSubmitted: undefined,
  onChange: undefined,
};

export default withTranslation(withStyles(styles)(CreditCardField));
