import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTheme, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import HelpText from '../../components/help-text';

import { withTranslation } from '../../config/i18n';

import { validateFields } from '../../utils';
import { signInAction } from '../../state/actions/SecurityActions';

import styles from './styles';

const renderRedirectToReferrer = (routeState) => (
  <Redirect to={routeState.from} />
);

const formValidationContraints = (t) => ({
  email: {
    email: {
      message: t('signIn.emailFormatError'),
    },
    length: {
      maximum: 256,
      message: t('signIn.emailLengthError'),
    },
  },
  password: {
    length: {
      minimum: 8,
      maximum: 16,
      message: t('signIn.passwordLengthError'),
    },
  },
});

class SignIn extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.shape({}).isRequired,
    onSignInClick: PropTypes.func.isRequired,
  };

  state = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
  };

  validateControls = () => {
    const { email, password, emailError, passwordError } = this.state;
    const { t } = this.props;
    const constraints = formValidationContraints(t);

    return validateFields(
      { email, password },
      constraints,
      { emailError, passwordError },
      (errors) => this.setState({ ...errors }),
    );
  };

  login = () => {
    const { email, password } = this.state;
    const { onSignInClick, security } = this.props;
    const isValidForm = this.validateControls();

    if (isValidForm) {
      onSignInClick(email, password, security.clientId);
    }
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailError: '',
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
      passwordError: '',
    });
  };

  render() {
    const { routeState, security, error, theme, classes, t } = this.props;
    const { emailError, passwordError } = this.state;
    const { isProcessing, redirectToReferrer } = security;
    const { errorCode } = error;

    if (redirectToReferrer && typeof routeState !== 'undefined') {
      return renderRedirectToReferrer(routeState);
    }

    return (
      <div className={classes.signInPage}>
        <Card className={classes.signInLoginBox}>
          <form noValidate autoComplete="on">
            <Typography
              variant="h6"
              style={{ color: theme.palette.text.primary }}
            >
              {t('signIn.title')}
            </Typography>
            {errorCode && (
              <HelpText
                className={classes.signInHelpText}
                error={t([
                  `signIn.errors.${errorCode}`,
                  'generalError.somethingWentWrong',
                ])}
                field="errorCode"
              />
            )}
            <FormControl className={classes.signInLoginControl}>
              <TextField
                disabled={isProcessing}
                id="email"
                label={t('signIn.email')}
                error={emailError !== ''}
                placeholder={t('signIn.emailPlaceholder')}
                margin="none"
                fullWidth
                onChange={this.handleEmailChange}
              />
              <HelpText error={emailError} field="email" />
            </FormControl>
            <FormControl className={classes.signInLoginControl}>
              <TextField
                disabled={isProcessing}
                id="password"
                label={t('signIn.password')}
                error={passwordError !== ''}
                type="password"
                placeholder={t('signIn.passwordPlaceholder')}
                margin="none"
                fullWidth
                onChange={this.handlePasswordChange}
              />
              <HelpText error={passwordError} field="password" />
            </FormControl>
            <FormControl className={classes.signInLoginControl}>
              <Button
                disabled={isProcessing}
                onClick={this.login}
                color="primary"
                variant="outlined"
              >
                {isProcessing
                  ? t('signIn.loggingInButtonLabel')
                  : t('signIn.signInButton')}
              </Button>
              <Link
                className={classes.signInLink}
                to={{ pathname: '/signup', state: routeState }}
              >
                <Button
                  disabled={isProcessing}
                  onClick={this.register}
                  color="primary"
                  variant="outlined"
                >
                  {t('signIn.signUpButton')}
                </Button>
              </Link>
            </FormControl>
            <FormControl className={classes.signInLoginControl}>
              <Button onClick={this.onBack} color="default" variant="outlined">
                {t('button.back')}
              </Button>
            </FormControl>
          </form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  security: state.security,
  error: state.error,
  routeState: ownProps.location.state,
});

const mapDispatchToProps = (dispatch) => ({
  onSignInClick: (username, password, clientId) => {
    dispatch(signInAction(username, password, clientId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(withTheme(withStyles(styles)(SignIn))));
