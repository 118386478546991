import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from '../../config/i18n';

import { oemName } from '../../utils';
import pciLogo from './img/pciLogo.png';
import mastercardLogo from './img/mc_symbol.svg';
import maestroLogo from './img/maestroLogo.svg';
import bancontactLogo from './img/bancontactLogo.svg';
import paymentReadyLogo from './img/payment-ready.svg';

import styles from './styles';

const IframeFooter = ({ security, classes, t, showCreditsImages }) => (
  <div className={classes.footerCreditsMainContainer}>
    {showCreditsImages && (
      <div className={classes.footerCreditsImages}>
        <img src={paymentReadyLogo} alt="payment ready logo" />
        <img src={mastercardLogo} alt="mastercard logo" />
        <img src={maestroLogo} alt="maestro logo" />
        <img src={bancontactLogo} alt="bancontact logo" />
        <img src={pciLogo} alt="pci logo" />
      </div>
    )}
    <Typography
      variant="caption"
      gutterBottom
      className={classes.footerCreditsText}
    >
      {t('loadingPage.footerText', { oemName: oemName(security.clientName) })}
    </Typography>
  </div>
);

IframeFooter.propTypes = {
  security: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(
  withTheme(withTranslation(withStyles(styles)(IframeFooter))),
);
