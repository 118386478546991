export default {
  palette: {
    primary: {
      main: '#6cc5e9',
    },
    text: { primary: '#333' },
    background: '#ffffff',
    action: {
      hover: '#6cc5e9',
      selected: '#6cc5e9',
    },
  },
};
