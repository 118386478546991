const styles = {
  signInPage: {
    width: '500px',
    height: '100%',
    textAlign: 'center',
    '&:before': {
      content: "' '",
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
  },
  signInLoginBox: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '20px',
    width: '300px',
    height: '350px',
  },
  signInbutton: {
    width: '100%',
    marginBottom: '8px',
  },
  signInLoginControl: {
    paddingTop: '10px',
    width: '100%',
    height: '70px',
    '&:last-child': {
      width: '100%',
      marginTop: '40px',
    },
  },
  signInHelpText: {
    textAlign: 'center',
  },
  signInLink: {
    textDecoration: 'none',
  },
};

export default styles;
