const cardConstrains = (t, securityCodeRequired, cardholderNameRequired) => {
  const securityCodeConstrains = securityCodeRequired && {
    securityCode: {
      length: {
        is: 3,
        message: t('creditCardForm.securityCodeLengthError'),
      },
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
        message: t('creditCardForm.securityCodeNumericalityError'),
      },
    },
  };

  const minYear = new Date().getYear() - 100;

  const cardholderNameConstrains = cardholderNameRequired && {
    cardholderName: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.cardholderNameMandatoryError'),
      },
      length: {
        maximum: 26,
        message: t('creditCardForm.cardholderNameLengthError'),
      },
    },
  };

  return {
    ...cardholderNameConstrains,
    expiryDate: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.expiryDateMandatoryError'),
      },
    },
    expiryMonth: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.expiryMonthMandatoryError'),
      },
      length: {
        minimum: 2,
        maximum: 2,
        message: t('creditCardForm.expiryMonthLengthError'),
      },
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
        lessThan: 13,
        message: t('creditCardForm.expiryMonthValueError'),
      },
    },
    expiryYear: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.expiryYearMandatoryError'),
      },
      length: {
        minimum: 2,
        maximum: 2,
        message: t('creditCardForm.expiryYearLengthError'),
      },
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: minYear,
        lessThanOrEqualTo: 99,
        message: t('creditCardForm.expiryYearValueError', { minYear }),
      },
    },
    ...securityCodeConstrains,
  };
};

const billingConstrains = (t, addressRequired) =>
  addressRequired !== false && {
    line1: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.line1MandatoryError'),
      },
      length: {
        maximum: 64,
        message: t('creditCardForm.line1LengthError'),
      },
    },
    line2: {
      length: {
        maximum: 64,
        message: t('creditCardForm.line2LengthError'),
      },
    },
    postalCode: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.postalCodeMandatoryError'),
      },
      length: {
        minimum: 3,
        message: t('creditCardForm.postalCodeLengthError'),
      },
    },
    country: {
      presence: {
        allowEmpty: false,
        message: t('creditCardForm.countryMandatoryError'),
      },
    },
  };

export { cardConstrains, billingConstrains };
