const styles = {
  creditCardFormPage: {
    width: '100%',
    maxWidth: '650px',
    minHeight: '380px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainContainer: {
    width: '100%',
    marginBottom: '20px',
  },
  securityCodeDisabled: {
    opacity: '0.2',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expiryDateContainer: {
    paddingTop: '20px',
    marginBottom: '-26px',
  },
};

export default styles;
