export default {
  palette: {
    primary: {
      main: '#FE9900',
    },
    text: { primary: '#333' },
    background: '#FFF',
    action: {
      hover: '#999999',
      selected: '#999999',
    },
  },
};
