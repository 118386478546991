import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const PanHelp = ({ showWhatIsPan, onGetPan, t, classes }) => {
  function ClickableLink() {
    return (
      <span
        className={classes.link}
        role="button"
        tabIndex={0}
        onClick={() => onGetPan()}
        onKeyDown={() => onGetPan()}
      >
        {t('components.creditCard.abnLink')}
      </span>
    );
  }

  return (
    <div className={classes.noPanHelpContainer}>
      {showWhatIsPan && (
        <div>
          <Typography variant="subtitle2">{t('components.creditCard.whatIsPan')}</Typography>
          <Typography variant="subtitle2">
            <Trans i18nKey="components.creditCard.abnLabel">
              <ClickableLink data-test="link" />
            </Trans>
          </Typography>
        </div>
      )}
    </div>
  );
};

PanHelp.propTypes = {
  showWhatIsPan: PropTypes.bool.isRequired,
  onGetPan: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.shape({ palette: PropTypes.object }).isRequired,
};

export default withStyles(styles)(PanHelp);
