const styles = {
  SignUpbuttonClass: {
    width: '100%',
    marginBottom: '8px',
  },

  signUpSignUpControl: {
    paddingTop: '10px',
    width: '100%',
    height: '70px',
  },

  signUpConfirmation: {
    padding: '1px 10px 0 10px',
    height: '100%',
    position: 'relative',
    '& > *': {
      textAlign: 'center',
      height: '100%',
    },
  },
  tcLabel: {
    marginTop: '10px',
  },
};

export default styles;
