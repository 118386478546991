import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { withTheme, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import HelpText from '../../../components/help-text';
import StyledButton from '../../../components/styled-button/StyledButon';
import SignUpTerms from '../sign-up-terms/SignUpTerms';
import EmailConfirmationMessage from '../email-confirmation-message/EmailConfirmationMessage';
import { validateFields, iframeResponse } from '../../../utils';

import styles from './styles';

const formValidationContraints = (t) => ({
  email: {
    email: {
      message: t('signUp.emailFormatError'),
    },
    length: {
      minimum: 1,
      maximum: 256,
      message: t('signUp.emailLengthError'),
    },
  },
  confirmEmail: {
    equality: {
      attribute: 'email',
      message: "email doesn't match",
    },
  },
});
class SignUpEmailForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    handleEmailCollected: PropTypes.func.isRequired,
    backPress: PropTypes.func.isRequired,
    isEmailFromParam: PropTypes.bool.isRequired,
    cardConfig: PropTypes.shape({
      emailSender: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const { email, isEmailFromParam } = props;
    this.state = {
      email,
      confirmEmail: email || '',
      emailError: '',
      confirmEmailError: '',
      confirmationMsg: isEmailFromParam,
      userExists: undefined,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
  }

  componentDidMount() {
    const { email, onCheckUserExists } = this.props;

    if (email) {
      const isEmailValid = this.validateControls();

      if (!isEmailValid) {
        iframeResponse('ERROR', 'the email query parameter is invalid!');
        this.closeConfirmationMsg();
        this.setState({ emailError: '', confirmEmailError: '' });
      } else {
        onCheckUserExists(
          email,
          () => {
            this.setState({
              userExists: true,
            });
          },
          () => {},
        );
      }
    }
  }

  closeConfirmationMsg = () => {
    this.setState({ confirmationMsg: false });
  };

  validateControls = () => {
    const { email, confirmEmail, emailError, confirmEmailError } = this.state;
    const { t } = this.props;
    const constraints = formValidationContraints(t);

    return validateFields(
      { email, confirmEmail },
      constraints,
      { emailError, confirmEmailError },
      (errors) => this.setState({ ...errors }),
    );
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailError: '',
    });
  };

  handleConfirmEmailChange = (event) => {
    this.setState({
      confirmEmail: event.target.value,
      confirmEmailError: '',
    });
  };

  validate() {
    const { onCheckUserExists } = this.props;
    const { email } = this.state;
    const isValidForm = this.validateControls();

    if (isValidForm) {
      onCheckUserExists(
        email,
        () => {
          this.setState({ confirmationMsg: true, userExists: true });
        },
        () => {
          this.setState({ confirmationMsg: true, userExists: false });
        },
      );
    }
    this.setState({ emailError: 'form not valid' });
  }

  render() {
    const {
      t,
      theme,
      classes,
      isProcessing,
      backPress,
      isCancel,
      onCancel,
      handleEmailCollected,
      cardConfig,
    } = this.props;
    const {
      emailError,
      confirmEmailError,
      confirmationMsg,
      email,
      userExists,
    } = this.state;

    if (isProcessing)
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );

    if (confirmationMsg) {
      return (
        <EmailConfirmationMessage
          t={t}
          theme={theme}
          email={email}
          userExists={Boolean(userExists)}
          onBack={this.closeConfirmationMsg}
          isProcessing={isProcessing}
          handleEmailCollected={handleEmailCollected}
        />
      );
    }
    return (
      <form
        noValidate
        autoComplete="off"
        className={classes.mainContainer}
        data-test="form"
      >
        <div
          className={classes.headerTextContainer}
          data-test="header-text-container"
        >
          <Typography className={classes.header} variant="subtitle1">
            {t(
              `signUp.alternative.${
                cardConfig.emailSender === 'Manage-Mii'
                  ? 'registerManageMii'
                  : 'enterEmail'
              }`,
            )}
          </Typography>
          <Typography className={classes.subheader} variant="caption">
            {t('signUp.alternative.useEmailMessage')}
          </Typography>
        </div>
        <div className={classes.inputContainer} data-test="input-container">
          <FormControl className={classes.inputEmail}>
            <TextField
              disabled={isProcessing}
              id="email"
              data-test="email"
              label={t('signUp.email')}
              error={emailError !== ''}
              placeholder={t('signUp.emailPlaceholder')}
              onChange={this.handleEmailChange}
            />
            <HelpText error={emailError} field="email" />
          </FormControl>

          <FormControl className={classes.inputEmail}>
            <TextField
              disabled={isProcessing}
              id="confirmEmail"
              data-test="confirmEmail"
              label={t('signUp.confirmEmail')}
              error={confirmEmailError !== ''}
              placeholder={t('signUp.confirmEmailPlaceholder')}
              onPaste={(e) => e.preventDefault()}
              onChange={this.handleConfirmEmailChange}
              onKeyPress={(e) => (e.key === 'Enter' ? this.validate() : null)}
            />
            <HelpText error={confirmEmailError} field="confirmEmail" />
          </FormControl>
        </div>
        <SignUpTerms t={t} theme={theme} data-test="signup-terms" />
        <div className={classes.buttonsContainer} data-test="buttons-container">
          <StyledButton
            disabled={isProcessing}
            onClick={isCancel ? onCancel : backPress}
            data-test="back"
          >
            {isCancel ? t('button.cancel') : t('button.back')}
          </StyledButton>
          <StyledButton
            disabled={isProcessing}
            onClick={() => this.validate()}
            data-test="continue"
          >
            {isProcessing
              ? t('signUp.pleaseWaitButtonLabel')
              : t('signUp.alternative.next')}
          </StyledButton>
        </div>
      </form>
    );
  }
}

export default withTheme(withStyles(styles)(SignUpEmailForm));
