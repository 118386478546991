import { SIGN_UP } from '../actions/ActionTypes';

const defaultSignUpState = {
  isProcessing: false,
  errorCode: undefined,
  success: false,
};

export default (state = defaultSignUpState, action) => {
  const isProcessing = action.status === 'processing';
  const success = action.status === 'success';
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        isProcessing,
        success,
        errorCode: action.error,
      };

    default:
      return state;
  }
};
