import {
  CARD_ELIGIBILITY_CHECK,
  ENROLL,
  UPDATE_CARD,
  UPDATE_BILLING,
  UPDATE_CARD_NUMBER,
  RESET_CARD,
  SET_DEVICE,
  UPDATE_CARD_VALIDITY,
  SET_CARD_CONFIG,
} from '../actions/ActionTypes';

const defaultEnrollmentState = {
  isFetching: false,
  card: {
    accountNumber: '',
    cardholderName: '',
    expiryDate: '',
    expiryMonth: '',
    expiryYear: '',
    securityCode: '',
  },
  billingAddress: undefined,
  eligibility: undefined,
  cardValidity: false,
  cardConfig: undefined,
};

export default (state = defaultEnrollmentState, action) => {
  const isFetching = action.status === 'processing';
  switch (action.type) {
    case CARD_ELIGIBILITY_CHECK:
      return {
        ...state,
        isFetching,
        eligibility: action.eligibility,
      };

    case SET_CARD_CONFIG: {
      return {
        ...state,
        cardConfig: { ...action.cardConfig },
      };
    }
    case UPDATE_CARD_NUMBER:
      return {
        ...state,
        card: {
          ...state.card,
          accountNumber: action.cardNumber,
        },
      };
    case UPDATE_CARD:
      return {
        ...state,
        card: { ...state.card, ...action.card },
      };
    case UPDATE_BILLING:
      return {
        ...state,
        billingAddress: { ...state.billingAddress, ...action.billingAddress },
      };
    case UPDATE_CARD_VALIDITY:
      return {
        ...state,
        cardValidity: action.cardValidity,
      };
    case RESET_CARD:
      return {
        ...state,
        ...defaultEnrollmentState,
      };
    case SET_DEVICE:
      return {
        ...state,
        device: action.device,
      };
    case ENROLL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
