const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-around',
    height: '400px',
  },
  header: {
    color: theme.palette.text.primary,
    fontSize: '22px',
  },
  subheader: {
    color: theme.palette.text.primary,
    fontSize: ' 14px',
  },
});

export default styles;
