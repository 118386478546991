const styles = (theme) => ({
  footerCreditsMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  footerCreditsImages: {
    display: 'flex',
    margin: '5px 0',
    flexDirection: 'row',
    height: '40px',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',

    '& img': {
      maxHeight: '100%',
      width: 'auto',
      objectFit: 'contain',
    },
    '@media (max-width: 400px)': {
      height: '30px',
    },
  },
  footerCreditsText: {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
});

export default styles;
