export default {
  palette: {
    type: 'light',
    primary: { main: '#092144' },
    text: { primary: '#333' },
    background: '#FFF',
    action: {
      hover: '#b8cbe0',
      selected: '#b8cbe0',
    },
  },
};
